import React from 'react'
import styled, { css } from 'styled-components'
import Markdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { darken, getLuminance, lighten } from 'polished'

interface RichSectionWrapperProps {
  primary: string
  secondary?: string
}

const getColorVars = (props: RichSectionWrapperProps) => {
  const colors = {
    primary: props.primary,
    secondary: props.secondary,
    textOverSecondary: '',
    textOverPrimary: '',
  }
  const primaryLight = getLuminance(colors.primary)
  if (primaryLight >= .5) {
    colors.secondary = darken(0.1, props.primary)
  } else {
    colors.secondary = lighten(0.1, props.primary)
  }
  colors.textOverSecondary = getLuminance(colors.secondary) >= 0.5 ? '#000' : '#fff'
  colors.textOverPrimary = getLuminance(colors.primary) >= 0.5 ? '#000' : '#fff'
  return colors
}

const RichSectionWrapper = styled.div`
  ${(props: RichSectionWrapperProps) => {
    const colors = getColorVars(props)
    return css`
    --primary: ${colors.primary};
    --secondary: ${colors.secondary};
    --text-over-secondary: ${colors.textOverSecondary};
    --text-over-primary: ${colors.textOverPrimary};
  `}}
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 60px;
  padding: 20px 20px;
  min-height: calc(100vh - 500px);
  font-family: 'Libre Baskerville';
  font-size: 18px;
  line-height: 1.7;
  h1, h2, h3 {
    font-family: 'Rammetto One';
  }
  h1 {
    color: var(--primary);
  }
  h2, h3 {
    color: var(--secondary);
  }
  blockquote {
    position: relative;
    margin-left: 12px;
    padding: 6px 20px;
    background-color: #ebebeb;
    border-radius: 0 9px 9px 0;
    :after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 3px;
      border-radius: 2px;
      height: 100%;
      background-color: var(--primary);
    }
  }

  code {
    background-color: var(--secondary);
    color: var(--text-over-secondary);
    padding: 0 3px;
    border-radius: 3px;
  }

  a {
    color: var(--secondary);
    text-decoration: underline;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 30px;
    margin: 20px 0;
    img {
      max-width: 100%;
      object-fit: contain;
      object-position: center;
      margin: 0 auto;
    }
  }
  .banner {
    width: 100%;
    max-height: 200px;
    overflow: hidden;
    border-radius: 10px;
    margin: 30px 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > * { margin-right: 20px; margin-bottom: 20px; }
    > :last-child { margin-right: 0; }
  }

  hr, .separator {
    width: 100%;
    margin: 20px auto;
    height: 3px;
    background-color: var(--primary);
  }

  .button {
    font-family: 'Rammetto One';
    color: #fff;
    background-color:#009EE2;
    border: 4px solid #00C5ED;
    padding: 2px 25px;
    border-radius: 100px;
    box-shadow: 0 3px 0 #025899, 0 6px 0 rgba(0,0,0,0.6);
    cursor: pointer;
    user-select: none;
    &.green {
      background-color: #8CC63F;
      color: #fff;
      border-color: #AFD752;
      box-shadow: 0 3px 0 #579800, 0 6px 0 rgba(0,0,0,0.6);
    }
    &.red {
      background-color: #E20514;
      color: #fff;
      border-color: #F60A21;
      box-shadow: 0 3px 0 #931120, 0 6px 0 rgba(0,0,0,0.6);
    }
    &.orange {
      background-color: #F29226;
      color: #fff;
      border-color: #F6B230;
      box-shadow: 0 3px 0 #CB6217, 0 6px 0 rgba(0,0,0,0.6);
    }
    &.purple {
      background-color: #701EA8;
      color: #fff;
      border-color: #9026C1;
      box-shadow: 0 3px 0 #4E1B6D, 0 6px 0 rgba(0,0,0,0.6);
    }
    :active {
      transform: translateY(6px);
      box-shadow: 0 0px 0 #000, inset 0 0px 0px 0px #000;
    }
  }
`


interface IProps {
  children: string,
  primary?: string
}

const RichTextRenderer = (props: IProps) => {
  return <RichSectionWrapper primary={props.primary || '#962E63'}>
    { props.children && <Markdown rehypePlugins={[rehypeRaw]}>
      {props.children}
    </Markdown> }
  </RichSectionWrapper>
}

RichTextRenderer.defaultProps = {
  primary: '#962E63'
}

export default RichTextRenderer
